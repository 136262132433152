import logo from "./logo.png"

const  clientData = {
   client_entity: 84,
   attorney_firm: 'MNB Partners, LLC',
    attorney_name: 'Benjamin R Stutz',
    attorney_number: '503-683-2503',
    attorney_email: 'contact@mnbpartnersllc.com',
    client_video_source: 'https://erc-videos.s3.amazonaws.com/ertc_mnb_partners%2C_llc+(720p).mp4',
    mainColor: '#004812',
    secondaryColor: '#004812',
    siteLink: '',
    logo
}

export default clientData